<template lang="pug">
    .shop-manage
        .list-item(v-for="it in shopList.filter(it=>it.shopType !== 1)")
            van-icon(name="shop-o")
            .item.ml10
                p
                    span 店铺名称：{{it.name}}
                    van-tag.tag(type="danger" v-if="it.ownerId===currentShop.ownerId" style="height:20px").ml5 当前店铺
                p.mt10 店铺ID: {{it.ownerId}}
                p.mt10 版本：{{it.version}}
                p.mt10 过期时间: {{it.expireTime}}
</template>

<script>

  import {mapGetters} from "vuex";

  export default {
    computed: {
      ...mapGetters(['shopList', 'currentShop'])
    }
  }
</script>

<style lang="stylus">
    .shop-manage
        padding 12px
        .list-item
            display flex
            padding: 12px;
            background-color: #fff;
            /*border-radius: 8px;*/
            margin-bottom 12px
            font-size 13px
            min-height 100px
            .tag
                position absolute
                right 20px
</style>
